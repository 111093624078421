import React, { useState, useEffect } from "react";
import TransactionContext from "./TransactionContext";
import axios from "axios";
import config from "../../Services/config.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import coins from "../../assets/Coinlist.json";

const TransactionState = ({ children }) => {
  const [coinslist, SetCoinsList] = useState(coins);
  const [exchangenamefrom, SetexchangeNamefrom] = useState("BTC");
  const [exchangesymbolfrom, SetexchangeSymbolfrom] = useState("btc");
  const [exchangefullnamefrom, SetexchangeFullnamefrom] = useState("");
  const [exchangeiconfrom, SetexchangeIconfrom] = useState("");
  const [cefivaluefrom, Setcefivaluefrom] = useState("0.1");
  const [addressfrom, SetAddressFrom] = useState("");
  const [networkfrom, SetNetworkFrom] = useState("");
  const [networkfromfinal, SetNetworkFromFinal] = useState("");
  const [networkidfrom, SetNetworkidfrom] = useState("");
  const [contractfrom, SetContractfrom] = useState("");
  const [search, SetSearch] = useState("");
  const [cefishowfrom, Setcefishowfrom] = useState("");
  const [defishowfrom, Setdefishowfrom] = useState("");
  const [memofrom, SetMemofrom] = useState("");
  const [hashfrom, SetHashfrom] = useState("");
  const [exchangenameto, SetexchangeNameto] = useState("ETH");
  const [exchangesymbolto, SetexchangeSymbolto] = useState("eth");
  const [exchangefullnameto, SetexchangeFullnameto] = useState("");
  const [exchangeiconto, SetexchangeIconto] = useState("");
  const [cefivalueto, Setcefivalueto] = useState("");
  const [address, SetAddress] = useState("");
  const [networkto, SetNetworkTo] = useState("");
  const [networktofinal, SetNetworkToFinal] = useState("");
  const [searchto, SetSearchto] = useState("");
  const [cefishowto, Setcefishowto] = useState("");
  const [defishowto, Setdefishowto] = useState("");
  const [memo, SetMemo] = useState("");
  const [hashto, SetHashto] = useState("");
  const [service, SetService] = useState("");
  const [exchangetype, SetExchangetype] = useState("");
  const [servicefinal, SetServicefinal] = useState("");
  const [exchangetypefinal, SetExchangetypefinal] = useState("");
  const [exchangenamefromfinal, SetExchangenamefromfinal] = useState("");
  const [cefivaluefromfinal, SetCefivaluefromfinal] = useState("");
  const [exchangenametofinal, SetExchangenametofinal] = useState("");
  const [cefivaluetofinal, SetCefivaluetofinal] = useState("");
  const [fixedtype, SetFixedtype] = useState();
  const [select, SetSelect] = useState(0);
  const [maxamount, SetMaxamount] = useState();
  const [minamount, SetMinamount] = useState();
  const [mainmaxamount, SetMainmaxamount] = useState();
  const [mainminamount, SetMainminamount] = useState();
  const [modalmain, SetModalmain] = useState(false);
  const [modalone, SetModalone] = useState(false);
  const [modaltwo, SetModaltwo] = useState(false);
  const [modalthree, SetModalthree] = useState(false);
  const [modalfour, SetModalfour] = useState(false);
  const [modalsetting, SetModalSetting] = useState(false);
  const [modalwallet, SetModalWallet] = useState(false);
  const [exchangelist, SetExchangelist] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [tokenid, SetTokenid] = useState(
    window.localStorage.getItem("token_id")
      ? window.localStorage.getItem("token_id")
      : ""
  );
  const [message, SetMessage] = useState(false);
  const [errormessage, SetErrorMessage] = useState(false);
  const [exchangemessage, SetExchangemessage] = useState(false);
  const [status, SetStatus] = useState();
  const [checkokstatus, SetCheckokstatus] = useState();
  const [exchangeid, SetExchangeid] = useState();
  const [exchangeidlist, SetExchangeidlist] = useState(
    window.localStorage.getItem("exchangeidlist")
      ? JSON.parse(window.localStorage.getItem("exchangeidlist"))
      : []
  );
  const [intervalid, setIntervalid] = useState();
  const [intervalcoin, setIntervalcoin] = useState("");
  const [waiting, SetWaiting] = useState(false);
  const [showactive, SetShowactive] = useState(true);
  const [searchid, SetSearchid] = useState("");
  const [statusmessage, SetStatusmessage] = useState("");
  const [showmobilehistory, SetShowmobilehistory] = useState(false);
  const [showmobiletracker, SetShowmobiletracker] = useState(false);
  const [menu, SetMenu] = useState(false);
  const [analytics, SetAnalytics] = useState("");
  const [exchangeData, setExchangeData] = useState("");

  const Handlecefivaluefrom = (event) => {
    const re = /^[0-9]*(\.)?[0-9]*$/;
    if (re.test(event.target.value)) {
      Setcefivaluefrom(event.target.value);
    } else {
      return false;
    }
    SetSelect(0);
  };

  const HandleAddress = (event) => {
    const newaddress = event.currentTarget.value.replace(/\s/g, "");
    SetAddress(newaddress);
    SetMessage(false);
  };
  const HandleMemo = (event) => {
    const newmemo = event.currentTarget.value.replace(/\s/g, "");
    SetMemo(newmemo);
  };
  const HandleCefiChange = (event) => {
    const newnamefrom = document.querySelector(
      ".currency_from span:nth-child(1)"
    ).innerHTML;
    const newnetworkfrom = document.querySelector(
      ".currency_from span:nth-child(2)"
    ).innerHTML;
    const newexchangesymbolfrom = document.querySelector(
      ".currency_from small"
    ).innerHTML;
    const newiconfrom = event.currentTarget.parentNode.parentNode.querySelector(
      ".crypto_one .crypto_item p:nth-child(1)"
    ).innerHTML;
    const newfullnamefrom =
      event.currentTarget.parentNode.parentNode.querySelector(
        ".crypto_one .crypto_item label"
      ).innerText;
    const newnameto = document.querySelector(
      ".currency_to span:nth-child(1)"
    ).innerHTML;
    const newnetworkto = document.querySelector(
      ".currency_to span:nth-child(2)"
    ).innerHTML;
    const newexchangesymbolto =
      document.querySelector(".currency_to small").innerHTML;
    const newiconto = event.currentTarget.parentNode.parentNode.querySelector(
      ".crypto_two .crypto_item p:nth-child(1)"
    ).innerHTML;
    const newfullnameto =
      event.currentTarget.parentNode.parentNode.querySelector(
        ".crypto_two .crypto_item label"
      ).innerText;
    SetexchangeSymbolfrom(newexchangesymbolto);
    SetexchangeSymbolto(newexchangesymbolfrom);
    SetexchangeNameto(newnamefrom);
    SetexchangeIconto(newiconfrom);
    SetexchangeFullnameto(newfullnamefrom);
    SetexchangeNamefrom(newnameto);
    SetexchangeIconfrom(newiconto);
    SetexchangeFullnamefrom(newfullnameto);
    SetNetworkFrom(newnetworkto);
    SetNetworkTo(newnetworkfrom);
    SetLoading(true);
    SetSelect(0);
  };

  const Handlecefishowfrom = () => {
    document.querySelector(".background_container").style.filter = "blur(4px)";
    Setcefishowfrom(true);
    SetModalmain(false);
    SetModalone(true);
    SetModaltwo(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetShowmobilehistory(false);
  };

  const Handlecefishowto = () => {
    document.querySelector(".background_container").style.filter = "blur(4px)";
    Setcefishowto(true);
    SetModalmain(false);
    SetModalone(true);
    SetModaltwo(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetShowmobilehistory(false);
  };
  const HandleMobileHistory = () => {
    document.querySelector(".background_container").style.filter = "blur(4px)";
    SetShowmobilehistory(!showmobilehistory);
    SetMenu(false);
    Setcefishowfrom(false);
    SetModalmain(false);
    SetModalone(true);
    SetModaltwo(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetShowmobiletracker(false);
  };

  const handleChat = () => {
    function onTidioChatApiReady() {
      window?.tidioChatApi.open();
    }
    if (window?.tidioChatApi) {
      window?.tidioChatApi.on("ready", onTidioChatApiReady);
    } else {
      document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    }
  }

  const HandleMobileTracker = () => {
    document.querySelector(".background_container").style.filter = "blur(4px)";
    SetShowmobiletracker(!showmobiletracker);
    SetMenu(false);
    Setcefishowfrom(false);
    SetModalmain(false);
    SetModalone(true);
    SetModaltwo(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetShowmobilehistory(false);
  };
  const Handledefishowfrom = () => {
    Setdefishowfrom(!defishowfrom);
  };

  const Handledefishowto = () => {
    Setdefishowto(!defishowto);
  };
  const HandleSearch = (e) => {
    SetSearch(e.target.value.toUpperCase());
  };
  const HandleSearchto = (e) => {
    SetSearchto(e.target.value.toUpperCase());
  };
  const HandleListCefi = (event) => {
    const newvalue =
      event.currentTarget.querySelector(".list_amount").innerText;
    Setcefivalueto(newvalue);
    const newexchangetype =
      event.currentTarget.querySelector(".list_exchangetype").innerText;
    SetExchangetype(newexchangetype);
    const newfixedtype =
      event.currentTarget.querySelector(".list_fixedtype").innerText;
    SetFixedtype(newfixedtype);
    const newservice =
      event.currentTarget.querySelector(".list_partnername").innerText;
    SetService(newservice);
    const newmax = event.currentTarget.querySelector(".list_max").innerText;
    SetMaxamount(newmax);
    const newmin = event.currentTarget.querySelector(".list_min").innerText;
    SetMinamount(newmin);
    SetShowactive(false);
  };
  const HandleMainClose = () => {
    clearInterval(intervalid);
    Setdefishowto(false);
    Setdefishowfrom(false);
    SetWaiting(false);
  };

  const HandleModalmain = () => {
    document.querySelector(".background_container").style.filter = "blur(4px)";
    SetModalmain(true);
    SetModalone(true);
    SetModaltwo(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
  };

  const HandleModalone = () => {
    SetModalone(true);
    SetModaltwo(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetWaiting(false);
  };
  const HandleModaltwo = () => {
    if (address === "") {
      SetModalone(true);
      SetMessage(true);
    } else {
      SetModalone(false);
      SetModaltwo(true);
      SetMessage(false);
    }
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
  };
  const HandleModalthree = () => {
    SetWaiting(true);
    axios
      .post(`${config.base_url}v1/createNewExchange`, {
        currency_from: exchangesymbolfrom,
        currency_to: exchangesymbolto,
        amount: cefivaluefrom,
        partner_name: service,
        to_address: address,
        extera_id: memo,
        fixed: JSON.parse(fixedtype),
        token_id: tokenid,
      })
      .then((response) => {
        SetCheckokstatus(response?.data?.Status);
        SetExchangeid(response?.data?.exchangeID);
        if (response?.data?.Status === "OK") {
          SetServicefinal(
            response?.data?.exchangeID.substring(
              0,
              response?.data?.exchangeID.indexOf("_")
            )
          );
          SetExchangetypefinal(
            response?.data?.exchangeID.substring(
              response?.data?.exchangeID.indexOf("_") + 1,
              response?.data?.exchangeID.lastIndexOf("_")
            )
          );
          const newexchangeidlist = [...exchangeidlist];
          newexchangeidlist.push({
            exchangeitem: response?.data?.exchangeID,
          });
          SetExchangeidlist(newexchangeidlist);
          window.localStorage.setItem(
            "exchangeidlist",
            JSON.stringify(newexchangeidlist)
          );
          function call() {
            axios
              .get(`${config.base_url}v1/getExchangeInfo`, {
                params: {
                  exchangeID: response?.data?.exchangeID,
                },
              })
              .then((response) => {
                let currency_from_index = coinslist.findIndex(
                  (obj) => obj.symbol === response?.data?.currency_from
                );
                let currency_to_index = coinslist.findIndex(
                  (obj) => obj.symbol === response?.data?.currency_to
                );
                setExchangeData(response?.data);
                SetAddress(response?.data?.address_to);
                SetAddressFrom(response?.data?.address_from);
                SetExchangenamefromfinal(
                  currency_from_index > -1
                    ? coinslist[currency_from_index].name
                    : response?.data?.currency_from.toUpperCase()
                );
                SetExchangenametofinal(
                  currency_to_index > -1
                    ? coinslist[currency_to_index].name
                    : response?.data?.currency_to.toUpperCase()
                );
                SetCefivaluefromfinal(response?.data?.amount_from);
                SetCefivaluetofinal(response?.data?.amount_to);
                SetStatus(response?.data?.status);
                SetMemo(response?.data?.extera_to);
                SetMemofrom(response?.data?.extera_from);
                SetHashfrom(response?.data?.hash_from);
                SetHashto(response?.data?.hash_to);
                SetNetworkidfrom(
                  currency_from_index > -1
                    ? coinslist[currency_from_index].networkid
                    : ""
                );
                SetContractfrom(
                  currency_from_index > -1
                    ? coinslist[currency_from_index].contract
                    : ""
                );
                SetNetworkFromFinal(
                  currency_from_index > -1
                    ? coinslist[currency_from_index].network
                    : ""
                );
                SetNetworkToFinal(
                  currency_to_index > -1
                    ? coinslist[currency_to_index].network
                    : ""
                );
              });
          }
          const newintervalid = setInterval(
            call,
            30 * 1000,
            call({ count: 0 })
          );
          setIntervalid(newintervalid);
          setTimeout(() => SetModalone(false), 4000);
          setTimeout(() => SetModaltwo(false), 4000);
          setTimeout(() => SetModalthree(true), 4000);
          setTimeout(() => SetWaiting(false), 4000);
          toast.success(
            "The transaction is created successfully! Wait a moment ...",
            {
              position: "bottom-left",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else if (response?.data?.Status === "Error") {
          toast.error(
            "Please check your address or try with another exchange!",
            {
              position: "bottom-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          SetModalone(false);
          SetModaltwo(true);
          SetModalthree(false);
          SetModalfour(false);
          setTimeout(() => SetWaiting(false), 3000);
        }
      });
  };

  const HandleModalSetting = () => {
    document.querySelector(".background_container").style.filter = "blur(5px)";
    SetModalSetting(true);
    SetModaltwo(false);
    SetModalone(false);
    SetModalthree(true);
    SetModalfour(false);
  };
  const HandleModalWallet = () => {
    document.querySelector(".background_container").style.filter = "blur(5px)";
    SetModalWallet(true);
    SetModaltwo(false);
    SetModalone(false);
    SetModalthree(true);
    SetModalfour(false);
    SetModalSetting(false);
  };
  const HandleSearchId = (event) => {
    SetSearchid(event.target.value);
    if (searchid !== "") {
      SetExchangemessage(false);
    }
  };
  const HandleTrack = () => {
    if (searchid === "") {
      SetExchangemessage(true);
    }

    if (searchid !== "") {
      document.querySelector(".background_container").style.filter =
        "blur(4px)";
      SetModalmain(true);
      SetModalone(false);
      SetModaltwo(false);
      SetModalthree(true);
      SetShowmobiletracker(false);

      function call() {
        axios
          .get(`${config.base_url}v1/getExchangeInfo`, {
            params: {
              exchangeID: searchid,
            },
          })
          .then((response) => {
            let currency_from_index = coinslist.findIndex(
              (obj) => obj.symbol === response?.data?.currency_from
            );
            let currency_to_index = coinslist.findIndex(
              (obj) => obj.symbol === response?.data?.currency_to
            );
            setExchangeData(response?.data);
            SetAddress(response?.data?.address_to);
            SetAddressFrom(response?.data?.address_from);
            SetExchangenamefromfinal(
              currency_from_index > -1
                ? coinslist[currency_from_index].name
                : response?.data?.currency_from.toUpperCase()
            );
            SetExchangenametofinal(
              currency_to_index > -1
                ? coinslist[currency_to_index].name
                : response?.data?.currency_to.toUpperCase()
            );
            SetCefivaluefromfinal(response?.data?.amount_from);
            SetCefivaluetofinal(response?.data?.amount_to);
            SetStatus(response?.data?.status);
            SetMemo(response?.data?.extera_to);
            SetMemofrom(response?.data?.extera_from);
            SetHashfrom(response?.data?.hash_from);
            SetHashto(response?.data?.hash_to);
            SetExchangeid(searchid);
            SetServicefinal(searchid.substring(0, searchid.indexOf("_")));
            SetExchangetypefinal(
              searchid.substring(
                searchid.indexOf("_") + 1,
                searchid.lastIndexOf("_")
              )
            );
            SetNetworkidfrom(
              currency_from_index > -1
                ? coinslist[currency_from_index].networkid
                : ""
            );
            SetContractfrom(
              currency_from_index > -1
                ? coinslist[currency_from_index].contract
                : ""
            );
            SetNetworkFromFinal(
              currency_from_index > -1
                ? coinslist[currency_from_index].network
                : ""
            );
            SetNetworkToFinal(
              currency_to_index > -1 ? coinslist[currency_to_index].network : ""
            );
          })
          .catch(function (error) {
            SetStatusmessage(error?.response?.data?.message);
          });
      }
      const newintervalid = setInterval(call, 30 * 1000, call({ count: 0 }));
      setIntervalid(newintervalid);
    }
  };
  const HandleMenu = () => {
    SetMenu(!menu);
  };

  const HandleHistory = (event, exchangeId = undefined) => {
    const newvalue = event === undefined ? exchangeId : event.target.innerText;
    document.querySelector(".background_container").style.filter = "blur(4px)";
    SetModalmain(true);
    SetModalone(false);
    SetModaltwo(false);
    SetModalthree(true);
    SetShowmobilehistory(false);
    function call() {
      axios
        .get(`${config.base_url}v1/getExchangeInfo`, {
          params: {
            exchangeID: newvalue,
          },
        })
        .then((response) => {
          let currency_from_index = coinslist.findIndex(
            (obj) => obj.symbol === response?.data?.currency_from
          );
          let currency_to_index = coinslist.findIndex(
            (obj) => obj.symbol === response?.data?.currency_to
          );
          setExchangeData(response?.data);
          SetAddress(response?.data?.address_to);
          SetAddressFrom(response?.data?.address_from);
          SetExchangenamefromfinal(
            currency_from_index > -1
              ? coinslist[currency_from_index].name
              : response?.data?.currency_from.toUpperCase()
          );
          SetExchangenametofinal(
            currency_to_index > -1
              ? coinslist[currency_to_index].name
              : response?.data?.currency_to.toUpperCase()
          );
          SetCefivaluefromfinal(response?.data?.amount_from);
          SetCefivaluetofinal(response?.data?.amount_to);
          SetStatus(response?.data?.status);
          SetMemo(response?.data?.extera_to);
          SetMemofrom(response?.data?.extera_from);
          SetHashfrom(response?.data?.hash_from);
          SetHashto(response?.data?.hash_to);
          SetExchangeid(newvalue);
          SetServicefinal(newvalue.substring(0, newvalue.indexOf("_")));
          SetExchangetypefinal(
            newvalue.substring(
              newvalue.indexOf("_") + 1,
              newvalue.lastIndexOf("_")
            )
          );
          SetNetworkidfrom(
            currency_from_index > -1
              ? coinslist[currency_from_index].networkid
              : ""
          );
          SetContractfrom(
            currency_from_index > -1
              ? coinslist[currency_from_index].contract
              : ""
          );
          SetNetworkFromFinal(
            currency_from_index > -1
              ? coinslist[currency_from_index].network
              : ""
          );
          SetNetworkToFinal(
            currency_to_index > -1 ? coinslist[currency_to_index].network : ""
          );
        });
    }
    const newintervalid = setInterval(call, 30 * 1000, call({ count: 0 }));
    setIntervalid(newintervalid);
  };

  const HandleModalRedo = () => {
    document.querySelector(".background_container").style.filter = "blur(0)";
    SetModalmain(false);
    SetLoading(true);
    axios
      .get(`${config.base_url}v1/getExchangeInfo`, {
        params: {
          exchangeID: searchid,
        },
      })
      .then((response) => {
        let currency_from_index = coinslist.findIndex(
          (obj) => obj.symbol === response?.data?.currency_from
        );
        let currency_to_index = coinslist.findIndex(
          (obj) => obj.symbol === response?.data?.currency_to
        );
        SetexchangeFullnamefrom(
          currency_from_index > -1
            ? coinslist[currency_from_index].fullname
            : ""
        );
        SetexchangeFullnameto(
          currency_to_index > -1 ? coinslist[currency_to_index].fullname : ""
        );
        SetexchangeIconfrom(
          currency_from_index > -1
            ? "<em class='icon-" +
                coinslist[currency_from_index].name +
                "'></em>"
            : ""
        );
        SetexchangeIconto(
          currency_to_index > -1
            ? "<em class='icon-" + coinslist[currency_to_index].name + "'></em>"
            : ""
        );
        SetexchangeNamefrom(
          currency_from_index > -1
            ? coinslist[currency_from_index].name
            : response?.data?.currency_from.toUpperCase()
        );
        SetNetworkFrom(
          currency_from_index > -1 ? coinslist[currency_from_index].network : ""
        );
        SetexchangeNameto(
          currency_to_index > -1
            ? coinslist[currency_to_index].name
            : response?.data?.currency_to.toUpperCase()
        );
        SetNetworkTo(
          currency_to_index > -1 ? coinslist[currency_to_index].network : ""
        );
        SetexchangeSymbolfrom(response?.data?.currency_from);
        SetexchangeSymbolto(response?.data?.currency_to);
        Setcefivaluefrom(response?.data?.amount_from);
        SetAddress(response?.data?.address_to);
        SetMemo(response?.data?.extera_to);
      })
      .catch(function (error) {
        SetStatusmessage(error?.response?.data?.message);
      });
    SetSearchid("");
    SetAddress("");
    setExchangeData("");
    SetAddressFrom("");
    SetMemo("");
    SetMemofrom("");
    SetModalWallet("");
    SetCheckokstatus("");
    SetHashfrom("");
    SetHashto("");
    SetCefivaluefromfinal("");
    SetCefivaluetofinal("");
    SetExchangenamefromfinal("");
    SetExchangenametofinal("");
    SetStatus("");
    SetServicefinal("");
    SetExchangetypefinal("");
    SetStatusmessage("");
    SetNetworkFromFinal("");
    SetNetworkToFinal("");
  };

  const HandleCloseFinished = () => {
    SetLoading(false);
    clearInterval(intervalid);
    document.querySelector(".background_container").style.filter = "blur(0)";
    SetModalmain(false);
    SetModaltwo(false);
    SetModalone(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetModalWallet(false);
    SetWaiting(false);
    SetShowmobiletracker(false);
    SetShowmobilehistory(false);
    SetSearchid("");
    SetSearchid("");
    SetAddress("");
    setExchangeData("");
    SetAddressFrom("");
    SetMemo("");
    SetMemofrom("");
    SetModalWallet("");
    SetCheckokstatus("");
    SetHashfrom("");
    SetHashto("");
    SetCefivaluefromfinal("");
    SetCefivaluetofinal("");
    SetExchangenamefromfinal("");
    SetExchangenametofinal("");
    SetStatus("");
    SetServicefinal("");
    SetExchangetypefinal("");
    SetStatusmessage("");
    SetNetworkFromFinal("");
    SetNetworkToFinal("");
    SetExchangemessage(false);
  };
  const HandleClose = (event) => {
    SetLoading(false);
    clearInterval(intervalid);
    document.querySelector(".background_container").style.filter = "blur(0)";
    SetModalmain(false);
    SetModaltwo(false);
    SetModalone(false);
    SetModalthree(false);
    SetModalfour(false);
    SetModalSetting(false);
    SetModalWallet(false);
    Setcefishowto(false);
    Setcefishowfrom(false);
    SetShowmobiletracker(false);
    SetShowmobilehistory(false);
    SetSearchid("");
    SetExchangemessage(false);
  };

  useEffect(() => {
    if (statusmessage === "Server Error") {
      clearInterval(intervalid);
    }
    if (
      localStorage.getItem("token_id") === null ||
      localStorage.getItem("token_id") === ""
    ) {
      var chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var newtokenid = "";
      for (var i = 0; i < 150; i++)
        newtokenid += chars[Math.floor(Math.random() * chars.length)];
      window.localStorage.setItem("token_id", newtokenid);
      SetTokenid(newtokenid);
    }
    const ourRequest = axios.CancelToken.source();
    function callcoin() {
      clearInterval(intervalcoin);
      SetLoading(true);
      axios
        .get(`${config.base_url}v3/FullEstimatedExchangeAmount`, {
          cancelToken: ourRequest.token,
          params: {
            currency_from: exchangesymbolfrom,
            currency_to: exchangesymbolto,
            amount: cefivaluefrom,
          },
        })
        .then((response) => {
          SetLoading(false);
          SetExchangelist(response?.data?.data);
          SetMainmaxamount(response?.data?.max_amount);
          SetMainminamount(response?.data?.min_amount);
          SetErrorMessage(response?.data?.message);
          Setcefivalueto(
            document.querySelector(".active .list_amount").innerText
          );
          SetService(
            document.querySelector(".active .list_partnername").innerText
          );
          SetExchangetype(
            document.querySelector(".active .list_exchangetype").innerText
          );
          SetMaxamount(document.querySelector(".active .list_max").innerText);
          SetMinamount(document.querySelector(".active .list_min").innerText);
          SetFixedtype(
            document.querySelector(".active .list_fixedtype").innerText
          );
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            console.error("Error:", error);
          }
        });
    }
    if (!modalmain) {
      const newintervalcoin = setInterval(
        callcoin,
        6 * 10000,
        callcoin({ count: 0 })
      );
      setIntervalcoin(newintervalcoin);
    } else {
      SetLoading(false);
      clearInterval(intervalcoin);
    }
    return () => {
      ourRequest.cancel("Component unmounted");
      clearInterval(intervalcoin);
    };
  }, [
    exchangesymbolfrom,
    exchangesymbolto,
    cefivaluefrom,
    modalthree,
    //cefivalueto,
    /*checkokstatus,
    toast,
    hashfrom,
    hashto,
    fixedtype,
    exchangeidlist,
    statusmessage,
    modalthree,
    modalmain,
    memofrom,*/
  ]);

  return (
    <TransactionContext.Provider
      value={{
        coinslist,
        exchangenamefrom,
        exchangesymbolfrom,
        exchangefullnamefrom,
        exchangeiconfrom,
        cefivaluefrom,
        maxamount,
        minamount,
        mainmaxamount,
        mainminamount,
        cefishowfrom,
        defishowfrom,
        exchangenameto,
        exchangesymbolto,
        exchangefullnameto,
        exchangeiconto,
        cefivalueto,
        cefishowto,
        defishowto,
        service,
        servicefinal,
        exchangetype,
        exchangetypefinal,
        exchangenamefromfinal,
        cefivaluefromfinal,
        exchangenametofinal,
        cefivaluetofinal,
        search,
        searchto,
        select,
        address,
        addressfrom,
        memo,
        memofrom,
        modalmain,
        modalone,
        modaltwo,
        modalthree,
        modalfour,
        modalsetting,
        modalwallet,
        exchangelist,
        loading,
        message,
        errormessage,
        exchangemessage,
        status,
        checkokstatus,
        exchangeid,
        hashfrom,
        hashto,
        fixedtype,
        intervalid,
        intervalcoin,
        waiting,
        showactive,
        exchangeidlist,
        searchid,
        statusmessage,
        showmobilehistory,
        menu,
        showmobiletracker,
        networkfrom,
        networkidfrom,
        networkto,
        contractfrom,
        networkfromfinal,
        networktofinal,
        exchangeData,
        Handlecefivaluefrom,
        Handlecefishowfrom,
        Handledefishowfrom,
        HandleCefiChange,
        Handlecefishowto,
        Handledefishowto,
        HandleSearch,
        HandleSearchto,
        HandleListCefi,
        HandleAddress,
        HandleMemo,
        HandleModalmain,
        HandleModalone,
        HandleModaltwo,
        HandleModalthree,
        HandleModalRedo,
        HandleModalSetting,
        HandleModalWallet,
        HandleClose,
        HandleMainClose,
        HandleCloseFinished,
        Setdefishowfrom,
        Setdefishowto,
        SetexchangeNamefrom,
        SetexchangeFullnamefrom,
        SetexchangeIconfrom,
        Setcefishowfrom,
        SetexchangeNameto,
        SetexchangeFullnameto,
        SetexchangeIconto,
        Setcefishowto,
        Setcefivaluefrom,
        SetService,
        SetSelect,
        SetExchangelist,
        SetLoading,
        SetMessage,
        SetSearch,
        SetSearchto,
        HandleTrack,
        HandleSearchId,
        HandleHistory,
        HandleMobileHistory,
        handleChat,
        HandleMenu,
        HandleMobileTracker,
        SetexchangeSymbolto,
        SetexchangeSymbolfrom,
        SetNetworkFrom,
        SetNetworkTo,
        setExchangeData,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionState;
