import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import Page from "../../Components/Page";
import RecipientAddress from "./Cefi/CefiModals/RecipientAddress";
import MobileWalletModal from "../../Components/MobileWalletModal";
import TransactionContext from "../../Contexts/Transaction";
import Footer from "../../Components/Footer";
/*import DefiSetting from "./Defi/DefiModals/DefiSetting"
import DefiPanel from "./Defi"
import DefiList from "./Defi/DefiList"
import DefiNetworkList from "./Defi/DefiNetworkList"*/
import CefiPanel from "./Cefi";
import CefiList from "./Cefi/CefiList";
import History from "./Cefi/History";
import SwapTracker from "./Cefi/SwapTracker";
import CoinsFrom from "./Cefi/CoinsFrom";
import CoinsTo from "./Cefi/CoinsTo";
import MobileHistory from "./Cefi/History/MobileHistory";
import MobileSwapTracker from "./Cefi/SwapTracker/MobileSwapTracker";
import { useSearchParams } from "react-router-dom";
import Tags from "./Cefi/CefiModals/Tags/Tags";
import TagsContext from "../../Contexts/TagsContext/TagsContext";

const TRACKING_ID = "G-GDDSRE1LP4";

const loadDataFromSearchParams = (checkhome, searchParams) => {
  checkhome.Setcefivaluefrom(
    searchParams.get("amount") == null ? 0.1 : searchParams.get("amount")
  );
  const currencyFromId = checkhome.coinslist.findIndex(
    (obj) => obj.symbol === searchParams.get("currency_from")
  );
  const currencyToId = checkhome.coinslist.findIndex(
    (obj) => obj.symbol === searchParams.get("currency_to")
  );

  if (currencyFromId > -1) {
    checkhome.SetexchangeNamefrom(checkhome.coinslist[currencyFromId].name);
    checkhome.SetexchangeSymbolfrom(checkhome.coinslist[currencyFromId].symbol);
    checkhome.SetexchangeFullnamefrom(
      checkhome.coinslist[currencyFromId].fullname
    );
    checkhome.SetexchangeIconfrom(
      "<em class='icon-" + checkhome.coinslist[currencyFromId].name + "'></em>"
    );
    checkhome.SetNetworkFrom(checkhome.coinslist[currencyFromId].network);
  }

  if (currencyToId > -1) {
    checkhome.SetexchangeNameto(checkhome.coinslist[currencyToId].name);
    checkhome.SetexchangeSymbolto(checkhome.coinslist[currencyToId].symbol);
    checkhome.SetexchangeFullnameto(checkhome.coinslist[currencyToId].fullname);
    checkhome.SetexchangeIconto(
      "<em class='icon-" + checkhome.coinslist[currencyToId].name + "'></em>"
    );
    checkhome.SetNetworkTo(checkhome.coinslist[currencyToId].network);
  }

  checkhome.SetLoading(true);
  checkhome.SetSelect(0);

  if (searchParams.get("exchangeID") != null) {
    checkhome.HandleHistory(undefined, searchParams.get("exchangeID"));
  }
};

const Home = () => {
    const checkhome = useContext(TransactionContext);
    const checkTags = useContext(TagsContext)
    const [tab, SetTab] = useState(1)
    const[id, setId] = useState()
    const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    loadDataFromSearchParams(checkhome, searchParams);
    ReactGA.initialize(TRACKING_ID);
  }, [searchParams]);

    return (
        <>
            {/*<PWAPrompt promptOnVisit={1} 
                        timesToShow={2} 
                        permanentlyHideOnDismiss={false} 
                        onClose={showChatBox} 
                        willNotShowPrompt={showChatBox}
            />*/}
            <MediaQuery minDeviceWidth={769}>
                <Page>
                    <StyledWrapper>
                        {/*<DefiPanel />*/}
                        {/*<DefiList />*/}
                        <CefiPanel />
                        <CefiList />
                        <SwapTracker />
                        <History />

                    </StyledWrapper>
                </Page>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={768}>
                <Page>
                    <StyledMobileWrapper>
                        <StyledInfo>
                            <StyledTab>
                                {/*<span className={tab === 1 ? "active" : null} onClick={x => SetTab(1)}>Exchange</span>*/}
                                {/*<span className={tab === 2 ? "active" : null} onClick={x => SetTab(2)}>Defi</span>*/}
                            </StyledTab>
                            {tab === 1 ?
                                <>
                                    <CefiPanel />
                                </>
                                : null}
                            {tab === 2 ?
                                <>
                                    {/*<DefiNetworkList />
                                    <DefiPanel />*/}
                </>
               : null}
            </StyledInfo>
          </StyledMobileWrapper>
          <Footer />
        </Page>
      </MediaQuery>
      {checkhome.modalmain ? <div className="wrapperFinalize"><RecipientAddress /></div> : null}
      {/*checkhome.modalsetting ?
                <DefiSetting
                />
            : null*/}
      {checkhome.modalwallet ? <MobileWalletModal /> : null}
      {checkhome.cefishowfrom ? <CoinsFrom /> : null}
      {checkhome.cefishowto ? <CoinsTo /> : null}
      {checkhome.showmobilehistory ? <MobileHistory /> : null}
      {checkhome.showmobiletracker ? <MobileSwapTracker /> : null}
      {checkTags.tagsModal ? <Tags /> : null}
    </>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(8, 1fr);
  > div {
    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 8;
    }
    &:nth-child(4) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 2;
      grid-row-end: 8;
    }
    &:nth-child(2) {
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 8;
      z-index: 1;
    }
    &:nth-child(3) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    &:nth-child(5) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    &:nth-child(6) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 2;
      grid-row-end: 5;
    }
  }
  @media (max-width: 1300px) {
    > div {
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &:nth-child(2) {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(4) {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      &:nth-child(6) {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 3;
        grid-row-end: 4;
      }
    }
  }
  @media (max-width: 992px) {
    > div {
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 4;
        grid-row-end: 5;
      }
      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 5;
        grid-row-end: 6;
      }
      &:nth-child(6) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 6;
        grid-row-end: 7;
      }
    }
  }
`;
const StyledMobileWrapper = styled.div`
  background: ${(props) => props.theme.color.ColorThree};
  border-radius: ${(props) => props.theme.radius.RadiusOne};
  width: 94%;
  position: relative;
  left: 3%;
  @media (max-width: 768px) {
    box-shadow: ${(props) => props.theme.shadow.ShadowOne};
  }
`;
const StyledInfo = styled.div`
  margin: 5px auto;
`;
const StyledTab = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  span {
    cursor: pointer;
    width: 50%;
    color: ${(props) => props.theme.color.ColorTwo};
    text-align: center;
    font-weight: 700;
    padding: 4px;
    font-size: 1.1rem;
    &:first-child {
      border-radius: ${(props) => props.theme.radius.RadiusOne} 0 0 0;
    }
    &:last-child {
      border-radius: 0 ${(props) => props.theme.radius.RadiusOne} 0 0;
    }
  }
  .active {
    background: ${(props) => props.theme.color.ColorEight};
  }
`;
export default Home;
