import { createContext } from "react";

const TransactionContext = createContext({
  HandleCefiChange: () => null,
  Handlecefivalueto: () => null,
  Handlecefivaluefrom: () => null,
  HandleModal: () => null,
  HandleClose: () => null,
  Handlecefishowfrom: () => null,
  Handlecefishowto: () => null,
  Handledefishowfrom: () => null,
  Handledefishowto: () => null,
  HandleSearch: () => null,
  HandleSearchto: () => null,
  HandleListCefi: () => null,
  HandleAddress: () => null,
  HandleMemo: () => null,
  HandleModalmain: () => null,
  HandleModalone: () => null,
  HandleModalRedo: () => null,
  HandleModaltwo: () => null,
  HandleModalthree: () => null,
  HandleModalSetting: () => null,
  HandleModalWallet: () => null,
  HandleMainClose: () => null,
  HandleCloseFinished: () => null,
  Setdefishowfrom: () => null,
  Setdefishowto: () => null,
  SetexchangeNamefrom: () => null,
  SetexchangeFullnamefrom: () => null,
  SetexchangeIconfrom: () => null,
  Setcefishowfrom: () => null,
  SetexchangeNameto: () => null,
  SetexchangeFullnameto: () => null,
  SetexchangeIconto: () => null,
  Setcefishowto: () => null,
  Setcefivaluefrom: () => null,
  SetService: () => null,
  SetSelect: () => null,
  SetExchangelist: () => null,
  SetLoading: () => null,
  SetMessage: () => null,
  Setcefivalueto: () => null,
  SetSearch: () => null,
  SetSearchto: () => null,
  SetAnalytics: () => null,
  HandleTrack: () => null,
  HandleSearchId: () => null,
  HandleHistory: () => null,
  HandleMobileHistory: () => null,
  handleChat: () => null,
  HandleMenu: () => null,
  HandleMobileTracker: () => null,
  SetexchangeSymbolto: () => null,
  SetexchangeSymbolfrom: () => null,
  SetNetworkFrom: () => null,
  SetNetworkTo: () => null,
  setExchangeData: () => null,
  HandleModalRedo: () => null,
  coinslist: "",
  exchangenamefrom: "",
  exchangefullnamefrom: "",
  exchangesymbolfrom: "",
  exchangesymbolto: "",
  exchangeiconfrom: "",
  cefivaluefrom: "",
  maxamount: "",
  minamount: "",
  mainmaxamount: "",
  mainminamount: "",
  cefishowfrom: "",
  defishowfrom: "",
  exchangenameto: "",
  exchangefullnameto: "",
  exchangeiconto: "",
  cefivalueto: "",
  cefishowto: "",
  defishowto: "",
  service: "",
  servicefinal: "",
  exchangetypefinal: "",
  exchangenamefromfinal: "",
  cefivaluefromfinal: "",
  exchangenametofinal: "",
  cefivaluetofinal: "",
  exchangetype: "",
  modal: "",
  search: "",
  searchto: "",
  select: "",
  memo: "",
  memofrom: "",
  address: "",
  addressfrom: "",
  modalmain: "",
  modalone: "",
  modaltwo: "",
  modalthree: "",
  modalfour: "",
  modalsetting: "",
  modalwallet: "",
  exchangelist: "",
  loading: "",
  message: "",
  errormessage: "",
  exchangemessage: "",
  status: "",
  checkokstatus: "",
  exchangeid: "",
  inputRef: "",
  data: "",
  error: "",
  errorMessage: "",
  hashfrom: "",
  hashto: "",
  fixedtype: "",
  intervalid: "",
  intervalcoin: "",
  waiting: "",
  showactive: "",
  exchangeidlist: "",
  searchid: "",
  statusmessage: "",
  showmobilehistory: "",
  menu: "",
  showmobiletracker: "",
  networkfrom: "",
  networkidfrom: "",
  networkto: "",
  contractfrom: "",
  networkfromfinal: "",
  networktofinal: "",
  analytics: "",
  exchangeData: "",
});

export default TransactionContext;
